exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acquirers-js": () => import("./../../../src/pages/acquirers.js" /* webpackChunkName: "component---src-pages-acquirers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-press-and-media-js": () => import("./../../../src/pages/press-and-media.js" /* webpackChunkName: "component---src-pages-press-and-media-js" */),
  "component---src-pages-products-zeal-apps-js": () => import("./../../../src/pages/products/zeal-apps.js" /* webpackChunkName: "component---src-pages-products-zeal-apps-js" */),
  "component---src-pages-products-zeal-bin-loyalty-js": () => import("./../../../src/pages/products/zeal-bin-loyalty.js" /* webpackChunkName: "component---src-pages-products-zeal-bin-loyalty-js" */),
  "component---src-pages-products-zeal-dashboard-js": () => import("./../../../src/pages/products/zeal-dashboard.js" /* webpackChunkName: "component---src-pages-products-zeal-dashboard-js" */),
  "component---src-pages-products-zeal-js": () => import("./../../../src/pages/products/zeal.js" /* webpackChunkName: "component---src-pages-products-zeal-js" */),
  "component---src-pages-products-zeal-pos-js": () => import("./../../../src/pages/products/zeal-pos.js" /* webpackChunkName: "component---src-pages-products-zeal-pos-js" */),
  "component---src-pages-sandbox-account-status-component-js": () => import("./../../../src/pages/sandbox/AccountStatusComponent.js" /* webpackChunkName: "component---src-pages-sandbox-account-status-component-js" */),
  "component---src-pages-sandbox-first-time-merchant-js": () => import("./../../../src/pages/sandbox/FirstTimeMerchant.js" /* webpackChunkName: "component---src-pages-sandbox-first-time-merchant-js" */),
  "component---src-pages-sandbox-footer-js": () => import("./../../../src/pages/sandbox/Footer.js" /* webpackChunkName: "component---src-pages-sandbox-footer-js" */),
  "component---src-pages-sandbox-header-js": () => import("./../../../src/pages/sandbox/Header.js" /* webpackChunkName: "component---src-pages-sandbox-header-js" */),
  "component---src-pages-sandbox-js": () => import("./../../../src/pages/sandbox.js" /* webpackChunkName: "component---src-pages-sandbox-js" */),
  "component---src-pages-sandbox-signup-form-js": () => import("./../../../src/pages/sandbox/SignupForm.js" /* webpackChunkName: "component---src-pages-sandbox-signup-form-js" */),
  "component---src-pages-sandbox-signup-js": () => import("./../../../src/pages/sandbox/signup.js" /* webpackChunkName: "component---src-pages-sandbox-signup-js" */),
  "component---src-pages-store-hub-js": () => import("./../../../src/pages/store-hub.js" /* webpackChunkName: "component---src-pages-store-hub-js" */),
  "component---src-pages-use-cases-business-plan-card-content-js": () => import("./../../../src/pages/use-cases/BusinessPlanCardContent.js" /* webpackChunkName: "component---src-pages-use-cases-business-plan-card-content-js" */),
  "component---src-pages-use-cases-fmcgs-js": () => import("./../../../src/pages/use-cases/fmcgs.js" /* webpackChunkName: "component---src-pages-use-cases-fmcgs-js" */)
}

